.stats{
    background-color: #833497;
    padding: 10px 10px;
    text-decoration: none;
    border-radius: 5px;
    color: white;
    display: block;
    width: 250px;
    margin-top: 5px;
}

.time-inputs{
    position: relative;
    top: 10px;
}