.login-page{
    margin-top: 5%;
    text-align: center;
}
.login-logo img{
    width: 250px;
}

.login-input input, .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input{
    border-color: #C69398;
}

.login-btn{
    background-color: #C69398 !important;
}