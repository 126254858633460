div#main {
    margin: 3% 5%;
}

div.addNewButton {
    margin-left: 6%;

}

div.addNewButton button {
    background: linear-gradient(90deg, rgba(244, 191, 197, 1) 0%, rgba(144, 27, 141, 1) 35%, rgba(175, 92, 102, 1) 100%);
    margin-left: 10%;

}

.list-doctors-box .css-dmmspl-MuiFormGroup-root{
    margin-top: 1%;
    background-color: #F4BFC5;
    border-radius: 10px;
    box-shadow: 8px -6px 5px #b5afaf;
    width: 307px;
    padding-left: 1%;
    padding-top: 1%;
    padding-bottom: 1%;
}

.doctor-select{
    width: 5em;
    margin-left: 5px !important;
    margin-top: 15px !important;
    
}

.time-inputs{
    margin-top: 5px !important;
}

.schedule-notes{
    width: 80%;
    margin-top: 15px;
}

.MuiCalendarPicker-root{    
    background-color: #F4BFC5 !important;
    border-radius: 10px;
    margin-top: 10px;
}


#month-calendar #next-appointments {
        background-color: #F4BFC5 !important;
        
        margin: 10px 20px;
        border-radius: 10px;
}

#month-calendar #next-appointments h3{
    padding: 20px 10px 10px 10px;
}

