.date-ranges{
    
    margin-top: 2%;
    margin-left: 5%;
}

#charts{
    
    padding: 10px 50px;
}

.pies{
    display: inline;
}