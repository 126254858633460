header{
    background-color: #F4BFC5 !important;
}

.logo{
    width: 100px;
}

.active{
    background-color: #af5c66;
    padding: 5px;
    border-radius: 5px;
    border-color: none;
}